// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_sF d_bD";
export var storyRowWrapper = "w_hx d_hx d_bK";
export var storyLeftWrapper = "w_sG d_bz d_bP";
export var storyWrapperFull = "w_sH d_cD";
export var storyWrapperFullLeft = "w_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "w_mS d_hy";
export var storyLeftWrapperCenter = "w_sJ d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "w_sK d_hF";
export var storyHeader = "w_sL d_hD d_w d_cs";
export var storyHeaderCenter = "w_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "w_hB d_hB d_by d_dw";
export var storyBtnWrapper = "w_sM d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "w_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "w_rj d_fg d_Z";
export var imageWrapperFull = "w_sN d_w d_H d_bf d_Z";
export var SubtitleSmall = "w_pd B_pd B_sV B_s5";
export var SubtitleNormal = "w_pf B_pf B_sV B_s6";
export var SubtitleLarge = "w_pg B_pg B_sV B_s7";
export var textLeft = "w_dv";
export var textCenter = "w_dw";
export var textRight = "w_dx";