// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sl d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_sm d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sn d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sp d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sq d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_sr d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_ss d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_st B_st";
export var heroExceptionNormal = "v_sv B_sv";
export var heroExceptionLarge = "v_sw B_sw";
export var Title1Small = "v_sx B_sx B_sV B_sW";
export var Title1Normal = "v_sy B_sy B_sV B_sX";
export var Title1Large = "v_sz B_sz B_sV B_sY";
export var BodySmall = "v_sB B_sB B_sV B_tc";
export var BodyNormal = "v_sC B_sC B_sV B_td";
export var BodyLarge = "v_sD B_sD B_sV B_tf";