// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "C_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "C_fR d_fR d_bz d_bJ";
export var menuDesign6 = "C_t2 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "C_t3 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "C_t4 d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "C_t5 d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "C_t6 d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "C_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "C_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "C_t7";
export var navbarItem = "C_nb d_bx";
export var navbarLogoItemWrapper = "C_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "C_t8 d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "C_t9 d_gd d_by d_Z d_bs";
export var burgerToggle = "C_vb d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "C_vc d_gd d_by d_Z d_bs";
export var burgerInput = "C_vd d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "C_vf d_f3 d_w d_H";
export var burgerLine = "C_vg d_f1";
export var burgerMenuDesign6 = "C_vh d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "C_vj d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "C_vk d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "C_vl d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "C_vm d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "C_vn d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "C_vp d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "C_vq d_bC d_bP";
export var compact = "C_vr";
export var navDivided = "C_vs";
export var staticBurger = "C_vt";
export var menu = "C_vv";
export var navbarDividedLogo = "C_vw";
export var nav = "C_vx";
export var fixed = "C_vy";